<template>
  <div>
    <nav-bar></nav-bar>
    <div class="banner">
      <div class="content wow bounceIn">
        <div class="title">郑州约克计算机技术有限公司</div>
        <div class="describe">
          集软件开发，硬件开发，系统集成，物联网，传感器定制，技术服务<br />于一体的多元化软硬件开发企业
        </div>
        <div class="about" @click="contact">联系我们</div>
      </div>
    </div>
    <BaseTest />
    <section class="wow fadeInUp">
      <div class="main flex justify-between">
        <div class="introduce wow fadeInLeft">
          <Title :title="'公司介绍'"></Title>
          <div class="flex margin-top-bg">
            <img src="../assets/images/index/introduce.png" />
            <div class="flex-sub relative">
              <div class="introduce_content">
                郑州约克计算机技术有限公司成立于2007年9月，
                集软件开发，硬件开发，系统集成，物联网，
                传感器定制，技术服务于一体的多元化
                软硬件开发企业。目前公司主营软件定制开发，
                技术服务，硬件开发，企业软件解决方案等。
              </div>
              <div class="opertion flex justify-between absolute ab_bottom">
                <div>企业文化</div>
                <div>企业简介</div>
              </div>
            </div>
          </div>
        </div>
        <div class="dynamic wow fadeInRight">
          <Title :title="'企业动态'"></Title>
          <div class="dynamic_content margin-top-sm">
            <div
              v-for="(item, index) in 4"
              :key="index"
              class="dynamicItem flex justify-between pointer"
            >
              <div class="ellipsis">
                一体化智慧管理信息平台正在维一体化智慧管理信息平台正在维...
              </div>
              <div>2020-11-10</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="main about wow fadeInUp">
        <Title :title="'关于我们'" ></Title>
        <div class="about_content margin-top-bg flex">
          <div class="flex-sub pointer relative select">
            <img src="../assets/images/index/about_back.png" />
            <div>
              <div>
                <img class="block" src="../assets/images/index/about1.png" />
                <img class="none" src="../assets/images/index/about4.png" />
              </div>
              <div class="title">我们的优势</div>
              <div class="describe">
                15年的技术积累，几十家企业软件的开发与实施经验。公司拥有多名专业博士做技术研究。十多名10年以上的软件开发技术人员。
              </div>
            </div>
          </div>

          <div class="flex-sub pointer relative select">
            <img src="../assets/images/index/about_back.png" />
            <div>
              <div>
                <img class="block" src="../assets/images/index/about5.png" />
                <img class="none" src="../assets/images/index/about2.png" />
              </div>
              <div class="title">我们主营项目</div>
              <div class="describe white-color">
                本公司主要提供软件定制开发，企业软件解决方案提供，物联网系统开发，传感器定制开发
              </div>
            </div>
          </div>
          <div class="flex-sub pointer relative select">
            <img src="../assets/images/index/about_back.png" />
            <div>
              <img class="block" src="../assets/images/index/about3.png" />
              <img class="none" src="../assets/images/index/about6.png" />
              <div class="title">我们团队</div>
              <div class="describe">
                我们拥有很强的技术团队支持以及业务能力较强的销售推广团队，每一个员工都会为公司的发展着想
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main news flex justify-between">
        <div class="wow fadeInLeft">
          <Title :title="'新闻资讯'" ></Title>
          <div class="news_content margin-top-bg">
            <img src="../assets/images/index/introduce.png" />
            <div
              v-for="(item, index) in 4"
              :key="index"
              class="newsItem flex pointer"
            >
              <div>11-10</div>
              <div class="ellipsis">
                一体化智慧管理信息平台正在维一体化智慧管理信息平台正在维...
              </div>
            </div>
          </div>
        </div>
        <div class="wow fadeInRight">
          <Title :title="'关于我们的产品'" ></Title>
          <!--<div class="app_content margin-top-bg">-->
          <div class="news_content margin-top-bg">
            
              <img src="../assets/images/index/p1.png" />
              
            
            <div>
              本公司开发的低代码开发框架，Vue3.0+Java，可以为企业管理软件提供极高效率的系统开发周期。
            </div>
          </div>
        </div>
      </div>
    </section>
    <footbar></footbar>
  </div>
</template>

<script>
import NavBar from "@/components/nav/index";
import footbar from "@/components/footbar/index";
import Title from "@/components/title/index";
import { WOW } from "wowjs";
export default {
  name: "index",
  components: { NavBar, footbar, Title },
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  methods:{
    contact(){
      this.$router.push("/about");
    }
  }
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 442px;
  background-color: transparent;
  background-image: url("../assets/images/banner/index.png");
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: table;
}
.banner .content {
      display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  text-align: center;
}
.banner .content .title {
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #ffffff;
}
.banner .content .describe {
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
  margin: 30px 0;
  letter-spacing: 2px;
}
.banner .content .about {
  width: 174px;
  height: 49px;
  border: 2px solid #ffffff;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 49px;
  margin: 0 auto;
  cursor: pointer;
}
section {
  background: #f4f4f4;
  padding: 50px 0;
}
section > div {
  margin-bottom: 50px;
}
/* 公司简介 */
section .introduce {
  width: 680px;
  background: #ffffff;
  padding: 30px;
}
.introduce_content {
  width: 282px;
  font-size: 14px;
  font-weight: 400;
  color: #737373;
  line-height: 26px;
  margin: 0 auto;
}
.introduce .opertion {
  width: 282px;
  margin: 0 auto;
  left: 17px;
}
.introduce img {
  width: 342px;
  height: 199px;
}
.opertion > div {
  width: 116px;
  height: 43px;
  background: #02b2b5;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 43px;
  text-align: center;
  cursor: pointer;
}
/* 公司动态 */
section .dynamic {
  width: 350px;
  padding: 30px;
  background: #ffffff;
}
section .dynamic .dynamicItem {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}
section .dynamic .dynamicItem:last-child {
  border: none;
}
section .dynamic .dynamicItem > div:nth-child(1) {
  width: 210px;
}
/* 关于我们 */
section .about {
  width: 1140px;
  background: #ffffff;
  padding: 30px 30px 50px 30px;
}
section .about .about_content {
  width: 1120px;
  height: 329px;
  margin: 30px auto 0;
  box-shadow: 0px 27px 46px 0px rgba(0, 0, 0, 0.15);
}
section .about .about_content .select > div img {
  width: 40px;
  height: 40px;
  display: block;
  margin: 60px auto 30px;
}
section .about .about_content .select > div .none {
  display: none;
}
section .about .about_content .select > div .block {
  display: block;
}
section .about .about_content .select .title {
  font-size: 18px;
  font-weight: 800;
  line-height: 35px;
  text-align: center;
  margin: 30px auto 20px;
  color: #333333;
}
section .about .about_content .select .describe {
  width: 322px;
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
  color: #7a7a7a;
}
section .about .about_content .select > div {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
}
section .about .about_content .select > img {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 1;
  display: none;
}
/* hover事件 */
section .about .about_content > div:hover .title {
  color: #ffffff;
}
section .about .about_content > div:hover .describe {
  color: #ffffff;
}
section .about .about_content > div:hover > img {
  display: block;
}
section .about .about_content > div:hover > div {
  background: rgba(11, 192, 192, 0.5);
}
section .about .about_content > div:hover > div .none {
  display: block;
}
section .about .about_content > div:hover > div .block {
  display: none;
}
/* 新闻 */
section .news{
  margin-bottom: 0;
}
section .news > div {
  width: 515px;
  background: #ffffff;
  padding: 30px;
}

section .news .news_content > img {
  width: 100%;
  height: 290px;
}
section .news .news_content .newsItem {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}
section .news .news_content .newsItem:last-child {
  border: none;
}
section .news .news_content .newsItem > div:nth-child(1) {
  color: #999999;
  margin-right: 20px;
}
section .news .news_content .newsItem > div:nth-child(2) {
  width: 450px;
}
.app_content img {
  width: 153px;
  height: 312px;
  position: relative;
  z-index: 2;
}
.app_content > div:nth-child(1) {
  height: 312px;
  position: relative;
}
.app_content > div:nth-child(1)::after {
  content: "";
  display: block;
  width: 100%;
  height: 124px;
  background: #02b2b5;
  bottom: 0;
  position: absolute;
  z-index: 1;
}
.app_content > div:nth-child(2) {
  height: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-top: 80px;
}
</style>
